import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseCarsColumns = [
  {
    label: i18n.t('base.cars.field.name'),
    field: 'name',
    showInDetails: true,
  },
  {
    label: i18n.t('base.cars.field.user'),
    field: 'user',
  },
  // {
  //   label: i18n.t('base.global.field.branch'),
  //   field: 'branch',
  // },
  {
    label: i18n.t('base.cars.field.status'),
    field: 'status',
  },
  {
    label: i18n.t('base.cars.field.mileage'),
    field: 'mileage',
  },
  {
    label: i18n.t('base.cars.field.inspection_date'),
    field: 'inspection',
    showInDetails: true,
  },
  {
    label: i18n.t('base.cars.field.service_date'),
    field: 'service',
    showInDetails: true,
  },
  {
    label: i18n.t('base.cars.field.location'),
    field: 'location',
  },
];

export default ref(createTable('base/cars', baseCarsColumns, {
  filters: {
    status_ids: [],
    group_ids: [],
    employee_ids: [],
    producer_model_ids: [],
  },
}));
