<template>
  <div class="base-cars-mileage-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.cars.reports.reports"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >

          <span v-if="column.field === 'date'">
            {{ formatDate.baseReport(row.report_date) }}
          </span>

          <span v-if="column.field === 'car'">
            <base-resource-label
              :resource="row.resource"
            />
          </span>

          <span v-if="column.field === 'driver'">
            <cadre-employee-label
              name-only
              :data="row.operator"
            />
          </span>

          <span v-if="column.field === 'type'">
            {{ row.resource_report_work_type?.name }}
          </span>

          <span v-if="column.field === 'purpose'">
            {{ row.purpose }}
          </span>

          <span v-if="column.field === 'mileage'">
            {{ row.meter_status }} km
          </span>

        </template>
      </vs-table>

      <!-- TODO: import as a component -->
      <b-modal
        id="modal-report-details"
        title="Szczegóły raportu"
        cancel-variant="outline-secondary"
        ok-title=""
        size="lg"
        cancel
        v-model="showDetailsModal"
        >

        <div class="entry">
          <div class="label">Data</div>
          <div class="value">{{ getFormattedDate(currentDetails.report_date) }}</div>
        </div>

        <div class="entry">
          <div class="label">Samochód</div>
          <div class="value">
            <router-link :to="{
              name: 'base.cars.details', params: { id: currentDetails.resource?.id }
            }">
              {{ getResourceName(currentDetails) }}
            </router-link>
          </div>
        </div>

        <div class="entry">
          <div class="label">Typ pracy</div>
          <div class="value">
            {{ currentDetails.resource_report_work_type?.name }}
          </div>
        </div>

        <div class="entry">
          <div class="label">Raportujący</div>
          <div class="value">
            <user-label :id="currentDetails.reporter_id" />
          </div>
        </div>

        <div class="entry">
          <div class="label">Kierowca</div>
          <div class="value">
            <cadre-employee-label
              nameOnly
              :data="currentDetails.operator"
            />
          </div>
        </div>

        <div class="entry">
          <div class="label">Raportowane kilometry</div>
          <div class="value">
            {{ Number(currentDetails.meter_status).toLocaleString() }} km
          </div>
        </div>

        <div class="entry">
          <div class="label">Lokalizacja</div>
          <div class="value">
            <!-- TODO whenever investments module will be ready -->
            -
          </div>
        </div>

        <div class="entry">
          <div class="label">Cel wyjazdu</div>
          <div class="value">
            {{ currentDetails.purpose ?? '-' }}
          </div>
        </div>

        <div class="entry">
          <div class="label">Opis trasy</div>
          <div class="value">
            {{ currentDetails.route_description ?? '-' }}
          </div>
        </div>

        <div class="entry">
          <div class="label">Uwagi</div>
          <div class="value">
            {{ currentDetails.comment }}
          </div>
        </div>

        <template #modal-footer>
          -
        </template>
      </b-modal>
    </authorization-guard>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { ref } from 'vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import baseCarsMileageTable from '@/hooks/tables/base/cars/mileage';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import formatDate from '@/libs/date-formatter';
import UserLabel from '@/components/ui/UserLabel.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import CadreEmployeeLabel from '../../cadre/ui/CadreEmployeeLabel.vue';
import BaseResourceLabel from '../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseCarsMileageTable',
  components: {
    VsTable,
    AuthorizationGuard,
    CadreEmployeeLabel,
    BaseResourceLabel,
    BModal,
    UserLabel,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns,
      onPageChange, rows,
    } = baseCarsMileageTable.value;

    const { modalEditReport } = useBaseModals();
    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditReport.value.open({ ...obj });
        },
      },
    ];

    const showDetailsModal = ref(false);
    const currentDetails = ref({});

    const openDetails = (data) => {
      showDetailsModal.value = true;
      currentDetails.value = data.row;
    };

    const getResourceName = (row) => row.resource?.name;
    const getFormattedDate = (date) => formatDate.baseReport(date);

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      showDetailsModal,
      openDetails,
      currentDetails,
      getResourceName,
      getFormattedDate,
    };
  },
};
</script>

<style lang="sass" scoped>
.entry
  margin: 20px 0

  .label
    opacity: 0.7

  .value
    font-size: 14pt
    font-weight: 500
</style>
