var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-service"
  }, [_c('div', {
    staticClass: "listWrapper",
    class: {
      fullWidth: !_vm.detailsVisible
    }
  }, [_c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource_service.index",
      "silent": ""
    }
  }, [_c('base-cars-service-filtration', {
    attrs: {
      "resource-type": _vm.backType
    }
  })], 1), _c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" + Dodaj serwis ")]), _c('br'), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "base.resource_service.index"
    }
  }, [_c('base-cars-service-table', {
    on: {
      "delete": _vm.deleteService,
      "openServiceDetails": _vm.openServiceDetails
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "detailsColumn",
    class: {
      visible: _vm.detailsVisible
    }
  }, [_vm.detailsVisible ? _c('base-service-details', {
    on: {
      "delete": _vm.deleteService,
      "close": _vm.closeServiceDetails
    }
  }) : _vm._e()], 1), _c('base-service-create-modal', {
    attrs: {
      "resourceTypes": [_vm.backType]
    },
    on: {
      "ok": _vm.fetchData
    }
  }), _c('base-service-edit-modal', {
    attrs: {
      "resourceTypes": [_vm.backType]
    },
    on: {
      "ok": _vm.fetchData
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }