<template>
  <vs-modal
    :modalInstance="modalCreateInsurance"
    @ok="createInsurance"
  >
    <base-insurance-form
      v-model="fields"
      :feedback="feedback"
      :resource="resource"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import baseInsuranceFormValidations from '@/services/forms/base/insurance';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { CreateInsuranceError, CreateInsuranceSuccess, AttachmentSizeError } from '@/libs/toasts/feedback/module/base';
import BaseInsuranceForm from '../forms/BaseInsuranceForm.vue';

export default {
  name: 'BaseInsuranceCreateModal',
  props: {
    resource: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const { modalCreateInsurance } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignFields,
      assignServerSideErrors,
      resetFields,
    } = useValidatedForm(baseInsuranceFormValidations);

    modalCreateInsurance.value.on({
      open(data) {
        resetFields();
        assignFields({ ...data });
      },
    });

    const createInsurance = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateInsurance.value.isLoading = true;

      const formData = new FormData();

      Object.keys(fields?.value).map((e) => {
        const name = fields?.value?.[e];
        formData.append(e, name === null ? '' : name);
        return true;
      });

      useBaseApi()
        .createInsurance(formData)
        .then(({ data }) => {
          showToast(CreateInsuranceSuccess, { policy: data.data.policy_number });
          modalCreateInsurance.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(CreateInsuranceError);
          }
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateInsurance.value.isLoading = false;
        });
    };

    return {
      modalCreateInsurance,
      fields,
      feedback,
      createInsurance,
    };
  },
  components: {
    VsModal,
    BaseInsuranceForm,
  },
};
</script>
