<template>
  <vs-modal
    :modalInstance="modalCreateResource"
    @ok="createResource"
  >
    <base-resource-form
      v-model="fields"
      :feedback="feedback"
      :type="type"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import baseResourceFormValidations from '@/services/forms/base/resource';
import { computed } from 'vue';
import showToast from '@/libs/toasts';
import { CreateResourceError, CreateResourceSuccess } from '@/libs/toasts/feedback/module/base';
import { BaseResourceExtraFields, BaseResourceExtraFieldType } from '@/hooks/base/extraFields';
import useBaseProducerModelStore from '@/stores/module/base/producer/useBaseProducerModelStore';
import BaseResourceForm from '../forms/BaseResourceForm.vue';

export default {
  name: 'BaseResourceCreateModal',
  emits: ['ok'],
  props: {
    type: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { modalCreateResource } = useBaseModals();

    const producerModelStore = useBaseProducerModelStore();

    const validationRules = computed(() => {
      if (!props.type) return baseResourceFormValidations.default;
      return baseResourceFormValidations[props.type] ?? baseResourceFormValidations.default;
    });

    const {
      fields,
      feedback,
      areAllFieldsValid,
      nonEmptyFields,
      assignServerSideErrors,
      resetFields,
    } = useValidatedForm({
      ...validationRules.value,
    });

    modalCreateResource.value.on({
      close() {
        resetFields();
      },
    });

    function generateExtraData(fieldsData) {
      return Object.entries(BaseResourceExtraFields)
        .filter(([slug]) => slug in fieldsData && fieldsData[slug] != null)
        .map(([slug, type]) => {
          const fieldValue = fieldsData[slug];

          return type === BaseResourceExtraFieldType.Input
            ? { base_resource_field_slug: slug, value: fieldValue }
            : {
              base_resource_field_slug: slug,
              base_resource_value_id: fieldValue,
            };
        });
    }

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateResource.value.isLoading = true;

      const model = producerModelStore.getById(fields.value.base_producer_model_id);

      const sendData = {
        ...nonEmptyFields.value,
        name: `${model.producer.name} ${model.name} ${fields.value.register_number}`,
        base_resource_activity: {
          ...fields.value.base_resource_activity,
          base_resource_collaboration: {
            ...fields.value.base_resource_activity?.base_resource_collaboration ?? {},
          },
        },
        ...(props.type === 'car'
          ? {
            extra_data: generateExtraData(fields.value),
          }
          : {}),
      };

      if (!sendData.base_resource_activity?.base_resource_collaboration?.crm_company_id) {
        delete sendData.base_resource_activity?.base_resource_collaboration;
      }

      useBaseApi()
        .createResource({
          ...sendData,
          base_resource_type_id: props.type,
        })
        .then(({ data }) => {
          showToast(CreateResourceSuccess, { name: data.data?.name });
          modalCreateResource.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(CreateResourceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateResource.value.isLoading = false;
        });
    };

    return {
      modalCreateResource,
      fields,
      feedback,
      createResource,
    };
  },
  components: {
    VsModal,
    BaseResourceForm,
  },
};
</script>
