var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data od"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową"
    },
    model: {
      value: _vm.filters.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_from", $$v);
      },
      expression: "filters.date_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data do"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową"
    },
    model: {
      value: _vm.filters.date_to,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_to", $$v);
      },
      expression: "filters.date_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Towarzystwo ubezpieczeniowe"
    }
  }, [_c('crm-provider-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.insurancy_company_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "insurancy_company_ids", $$v);
      },
      expression: "filters.insurancy_company_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Samochód"
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "multiple": "",
      "excluded": function (resource) {
        var _resource$type;
        return (resource === null || resource === void 0 ? void 0 : (_resource$type = resource.type) === null || _resource$type === void 0 ? void 0 : _resource$type.slug) !== 'car';
      }
    },
    model: {
      value: _vm.filters.resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "resource_id", $$v);
      },
      expression: "filters.resource_id"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }