<template>
  <b-card class="base-cars-mileage">
    <base-cars-mileage-filtration />

    <vs-button
      vs-variant="primary"
      @click="openCreateModal"
    >
      + Dodaj raport
    </vs-button>
    <br>
    <br>

    <base-cars-mileage-table />

    <machines-mth-report-modal
      type="car"
      @ok="fetchData"
    />
    <base-resource-report-edit-modal
      type="car"
      @ok="fetchData"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseCarsMileageFiltration from '@/components/views/base/cars/BaseCarsMileageFiltration.vue';
import BaseCarsMileageTable from '@/components/views/base/cars/BaseCarsMileageTable.vue';
import baseCarsMileageTable from '@/hooks/tables/base/cars/mileage';
import MachinesMthReportModal from '@/components/views/base/machines/modals/MachinesMthReportModal.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseResourceReportEditModal from '@/components/views/base/machines/modals/BaseResourceReportEditModal.vue';

export default {
  name: 'BaseCarsMileage',
  setup() {
    const { fetchData } = baseCarsMileageTable.value;
    const { modalCreateReport } = useBaseModals();
    const openCreateModal = () => {
      modalCreateReport.value.open();
    };

    return { fetchData, openCreateModal };
  },
  components: {
    BCard,
    BaseCarsMileageFiltration,
    BaseCarsMileageTable,
    MachinesMthReportModal,
    VsButton,
    BaseResourceReportEditModal,
  },
};
</script>
