var render = function render(){
  var _vm$currentDetails$re, _vm$currentDetails$re2, _vm$currentDetails$pu, _vm$currentDetails$ro;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-mileage-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.cars.reports.reports,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$resource_report_;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.baseReport(row.report_date)) + " ")]) : _vm._e(), column.field === 'car' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource
          }
        })], 1) : _vm._e(), column.field === 'driver' ? _c('span', [_c('cadre-employee-label', {
          attrs: {
            "name-only": "",
            "data": row.operator
          }
        })], 1) : _vm._e(), column.field === 'type' ? _c('span', [_vm._v(" " + _vm._s((_row$resource_report_ = row.resource_report_work_type) === null || _row$resource_report_ === void 0 ? void 0 : _row$resource_report_.name) + " ")]) : _vm._e(), column.field === 'purpose' ? _c('span', [_vm._v(" " + _vm._s(row.purpose) + " ")]) : _vm._e(), column.field === 'mileage' ? _c('span', [_vm._v(" " + _vm._s(row.meter_status) + " km ")]) : _vm._e()];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-report-details",
      "title": "Szczegóły raportu",
      "cancel-variant": "outline-secondary",
      "ok-title": "",
      "size": "lg",
      "cancel": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_vm._v(" - ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showDetailsModal,
      callback: function ($$v) {
        _vm.showDetailsModal = $$v;
      },
      expression: "showDetailsModal"
    }
  }, [_c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Data")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.getFormattedDate(_vm.currentDetails.report_date)))])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Samochód")]), _c('div', {
    staticClass: "value"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'base.cars.details',
        params: {
          id: (_vm$currentDetails$re = _vm.currentDetails.resource) === null || _vm$currentDetails$re === void 0 ? void 0 : _vm$currentDetails$re.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getResourceName(_vm.currentDetails)) + " ")])], 1)]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Typ pracy")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s((_vm$currentDetails$re2 = _vm.currentDetails.resource_report_work_type) === null || _vm$currentDetails$re2 === void 0 ? void 0 : _vm$currentDetails$re2.name) + " ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Raportujący")]), _c('div', {
    staticClass: "value"
  }, [_c('user-label', {
    attrs: {
      "id": _vm.currentDetails.reporter_id
    }
  })], 1)]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Kierowca")]), _c('div', {
    staticClass: "value"
  }, [_c('cadre-employee-label', {
    attrs: {
      "nameOnly": "",
      "data": _vm.currentDetails.operator
    }
  })], 1)]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Raportowane kilometry")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(Number(_vm.currentDetails.meter_status).toLocaleString()) + " km ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Lokalizacja")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" - ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Cel wyjazdu")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s((_vm$currentDetails$pu = _vm.currentDetails.purpose) !== null && _vm$currentDetails$pu !== void 0 ? _vm$currentDetails$pu : '-') + " ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Opis trasy")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s((_vm$currentDetails$ro = _vm.currentDetails.route_description) !== null && _vm$currentDetails$ro !== void 0 ? _vm$currentDetails$ro : '-') + " ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Uwagi")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.currentDetails.comment) + " ")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }