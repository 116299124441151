<template>
  <div class="base-cars-examination-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.examination.service"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'date'">
            {{ formatDate.transport(row.date) }}
          </span>
          <span v-else-if="column.field === 'resource'">
            <base-resource-label :resource="row.resource" />
          </span>
          <span v-else-if="column.field === 'type'">
            {{ $t(`base.service.type.${row.type}`) }}
          </span>
          <span v-else-if="column.field === 'status'">
            <vs-status-badge :data="row.status" />
          </span>
          <span v-else-if="column.field === 'reporter'">
            <user-label withAvatar :data="row.reporter" />
          </span>
          <span v-else-if="column.field === 'acceptor'">
            <user-label withAvatar :data="row.accepting" />
          </span>
          <span v-else-if="column.field === 'name'">
            {{ row.resource.name }}
          </span>
        </template>
      </vs-table>
    </authorization-guard>
  </div>
</template>

<script>
import VsTable from '@/components/ui/vs-table/Table.vue';
import baseCarsExaminationColumns from '@/hooks/tables/base/cars/examination';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import formatDate from '@/libs/date-formatter';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseResourceLabel from '../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseCarsServiceTable',
  components: {
    VsTable,
    AuthorizationGuard,
    VsStatusBadge,
    UserLabel,
    BaseResourceLabel,
  },
  setup(_, { emit }) {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = baseCarsExaminationColumns.value;

    const { modalEditService } = useBaseModals();
    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditService.value.open({ ...obj });
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: (obj) => {
          emit('delete', obj);
        },
      },
    ];

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      fetchData,
    };
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'base.cars.examinations.details', params: { id: data.row.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-cars-examination-table {
  width: 100%;
}
</style>
