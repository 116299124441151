<template>
  <vs-modal
    :modalInstance="modalPreviewInsurance"
  >
    <base-cars-insurance-card
      :prefetchedInsurance="insurance"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import { ref } from 'vue';
import BaseCarsInsuranceCard from '../../cars/widgets/BaseCarsInsuranceCard.vue';

export default {
  name: 'BaseInsuranceCreateModal',
  props: {
    resource: {
      type: Object,
    },
  },
  setup() {
    const { modalPreviewInsurance } = useBaseModals();
    const insurance = ref(null);

    modalPreviewInsurance.value.on({
      open(data) {
        insurance.value = data;
      },
      close() {
        insurance.value = null;
      },
    });

    return {
      modalPreviewInsurance,
      insurance,
    };
  },
  components: {
    VsModal,
    BaseCarsInsuranceCard,
  },
};
</script>
