var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalEditReport
    },
    on: {
      "ok": _vm.createReport
    }
  }, [_c('resource-report-form', {
    attrs: {
      "feedback": _vm.feedback,
      "refuel": _vm.reportFuel,
      "resourceEditable": false
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }