<template>
  <div class="base-cars-list">
    <b-card>
      <authorization-guard permission="base.resource.index" silent>
        <cars-list-filtration />
      </authorization-guard>

      <div class="buttons">
        <div class="group d-flex justify-content-between">
          <vs-button
            @click="openResourceCreateModal"
            vs-variant="primary"
            v-can:store="'base.resource'"
          >
            + {{ $t('base.cars.button.addCar') }}
          </vs-button>

          <vs-button vs-variant="light" @click="handleExport">
            <feather-icon size="14" icon="DownloadIcon" />
          </vs-button>

          <a class="d-none" ref="exportAnchorRef" />
        </div>
      </div>
      <br />

      <cars-list-table />

      <base-resource-create-modal type="car" @ok="fetchData" />
      <base-resource-edit-modal type="car" @ok="fetchData" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import baseCarsTable from '@/hooks/tables/base/cars';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import CarsListFiltration from '@/components/views/base/cars/CarsListFiltration.vue';
import CarsListTable from '@/components/views/base/cars/CarsListTable.vue';
import BaseResourceCreateModal from '@/components/views/base/resource/modals/BaseResourceCreateModal.vue';
import BaseResourceEditModal from '@/components/views/base/resource/modals/BaseResourceEditModal.vue';
import ApiClient from '@/services/api';
import { ref } from 'vue';
import showToast from '@/libs/toasts';
import {
  ExportDataSuccess,
  ExportDataError,
} from '@/libs/toasts/feedback/module/base';
import formatDate from '@/libs/date-formatter';

export default {
  components: {
    VsButton,

    BCard,
    AuthorizationGuard,
    CarsListFiltration,
    CarsListTable,
    BaseResourceCreateModal,
    BaseResourceEditModal,
  },
  setup() {
    const { modalCreateResource } = useBaseModals();
    const openResourceCreateModal = () => {
      modalCreateResource.value.open();
    };

    const { fetchData, getFetchParams } = baseCarsTable.value;

    const exportAnchorRef = ref();

    const handleExport = async () => {
      const anchorRef = exportAnchorRef.value;
      if (!anchorRef) return;

      const fetchParams = getFetchParams();

      ApiClient.get('api/base/resource/export', {
        ...fetchParams,
        columnFilters: {
          ...(fetchParams.columnFilters ?? {}),
          type_ids: ['car'],
        },
      })
        .then(({ data }) => {
          const timestamp = formatDate.standardTimed(Date.now());
          anchorRef.href = `data:application/xlsx;base64,${data.data}`;
          anchorRef.download = `Budokop - Samochody - ${timestamp}.xlsx`;
          anchorRef.click();
          showToast(ExportDataSuccess);
        })
        .catch((err) => {
          showToast(ExportDataError);
          console.log('error ', err);
        });
    };

    return {
      openResourceCreateModal,
      fetchData,
      exportAnchorRef,
      handleExport,
    };
  },
};
</script>
