<template>
  <div class="base-cars-insurance-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.cars.insurance.insurance"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'car'">
            <base-resource-label
              :resource="row.resource"
              type="car"
            />
          </span>
          <span v-if="column.field === 'date_from'">
            {{ formatDate.insurance(row.date_from) }}
          </span>
          <span v-if="column.field === 'date_to'">
            {{ formatDate.insurance(row.date_to) }}
          </span>
          <span v-if="column.field === 'company'">
            <crm-company-label
              :integrated="row.insurance_company?.company"
              companyType="provider"
            />
          </span>
          <span v-if="column.field === 'police'">
            {{ row.policy_number }}
          </span>
        </template>
      </vs-table>
    </authorization-guard>

    <base-insurance-preview-modal />
  </div>
</template>

<script>

import VsTable from '@/components/ui/vs-table/Table.vue';
import baseCarsInsuranceTable from '@/hooks/tables/base/cars/insurance';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import formatDate from '@/libs/date-formatter';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import useBaseApi from '@/hooks/base/useBaseApi';
import { DeleteInsuranceError, DeleteInsuranceSuccess } from '@/libs/toasts/feedback/module/base';
import showToast from '@/libs/toasts';
import BaseInsurancePreviewModal from '../insurance/modals/BaseInsurancePreviewModal.vue';
import BaseResourceLabel from '../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseCarsInsuranceTable',
  components: {
    VsTable,
    AuthorizationGuard,
    BaseResourceLabel,
    CrmCompanyLabel,
    BaseInsurancePreviewModal,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = baseCarsInsuranceTable.value;

    const { modalPreviewInsurance, modalEditInsurance } = useBaseModals();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditInsurance.value.open({ ...obj });
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: async (insurance) => {
          const confirmation = await deleteModal({
            title: i18n.t('base.cars.insurance.modal.delete.title'),
            text: i18n.t('base.cars.insurance.modal.delete.text', {
              policy: insurance.policy_number,
            }),
          });

          if (!confirmation) return false;

          return useBaseApi()
            .deleteInsurance(insurance.id)
            .then(() => {
              showToast(DeleteInsuranceSuccess, { policy: insurance.policy_number });
              fetchData();
            })
            .catch(() => showToast(DeleteInsuranceError));
        },
      },
    ];

    const openDetails = ({ row }) => {
      modalPreviewInsurance.value.open({ ...row });
    };

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      openDetails,
    };
  },
};
</script>
