var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data badania od"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową"
    },
    model: {
      value: _vm.filters.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_from", $$v);
      },
      expression: "filters.date_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data badania do"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową"
    },
    model: {
      value: _vm.filters.date_to,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_to", $$v);
      },
      expression: "filters.date_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('base-service-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.status_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status_ids", $$v);
      },
      expression: "filters.status_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Osoba akceptująca"
    }
  }, [_c('core-user-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.accepting_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "accepting_ids", $$v);
      },
      expression: "filters.accepting_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Osoba zgłaszająca"
    }
  }, [_c('core-user-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.reporting_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "reporting_ids", $$v);
      },
      expression: "filters.reporting_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Zasób"
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "multiple": "",
      "excluded": _vm.shouldResourceBeExcluded
    },
    model: {
      value: _vm.filters.resource_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "resource_ids", $$v);
      },
      expression: "filters.resource_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }