<template>
  <vs-modal
    :modalInstance="modalEditInsurance"
    @ok="createInsurance"
  >
    <base-insurance-form
      v-model="fields"
      :feedback="feedback"
      :resource="resource"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import baseInsuranceFormValidations from '@/services/forms/base/insurance';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { UpdateInsuranceError, UpdateInsuranceSuccess } from '@/libs/toasts/feedback/module/base';
import BaseInsuranceForm from '../forms/BaseInsuranceForm.vue';

export default {
  name: 'BaseInsuranceCreateModal',
  props: {
    resource: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const { modalEditInsurance } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignFields,
      assignServerSideErrors,
      resetFields,
    } = useValidatedForm({
      id: 'required',
      ...baseInsuranceFormValidations,
    });

    modalEditInsurance.value.on({
      open(data) {
        resetFields();
        assignFields({ ...data });
      },
    });

    const createInsurance = async () => {
      if (!(await areAllFieldsValid())) return;

      modalEditInsurance.value.isLoading = true;

      const formData = new FormData();

      Object.keys(fields?.value).map((e) => {
        const name = fields?.value?.[e];
        if (name === null) return false;
        if (name === true || name === false) {
          formData.append(e, +name);
          return true;
        }
        formData.append(e, name);
        return true;
      });

      useBaseApi()
        .updateInsurance(fields.value.id, formData)
        .then(({ data }) => {
          showToast(UpdateInsuranceSuccess, { policy: data.data.policy_number });
          modalEditInsurance.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(UpdateInsuranceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalEditInsurance.value.isLoading = false;
        });
    };

    return {
      modalEditInsurance,
      fields,
      feedback,
      createInsurance,
    };
  },
  components: {
    VsModal,
    BaseInsuranceForm,
  },
};
</script>
