import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseCarsServiceColumns = [
  {
    label: i18n.t('base.cars.mileage.field.date'),
    field: 'date',
  },
  {
    label: i18n.t('base.cars.mileage.field.car'),
    field: 'car',
  },
  {
    label: i18n.t('base.cars.mileage.field.driver'),
    field: 'driver',
  },
  {
    label: i18n.t('base.cars.mileage.field.type'),
    field: 'type',
  },
  {
    label: i18n.t('base.cars.mileage.field.purpose'),
    field: 'purpose',
  },
  {
    label: i18n.t('base.cars.mileage.field.mileage'),
    field: 'mileage',
  },
];

export default ref(createTable('base/cars/reports', baseCarsServiceColumns, {
  filters: {
    date_from: null,
    date_to: null,
    work_type_ids: [],
    operator_ids: [],
    resource_ids: [],
  },
}));
