import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseCarsInsuranceColumns = [
  {
    label: i18n.t('base.cars.insurance.field.police'),
    field: 'police',
  },
  {
    label: i18n.t('base.cars.insurance.field.car'),
    field: 'car',
  },
  {
    label: i18n.t('base.cars.insurance.field.company'),
    field: 'company',
  },
  {
    label: i18n.t('base.cars.insurance.field.date_from'),
    field: 'date_from',
  },
  {
    label: i18n.t('base.cars.insurance.field.date_to'),
    field: 'date_to',
  },
];

export default ref(createTable('base/cars/insurance', baseCarsInsuranceColumns, {
  filters: {
    insurance_company_ids: [],
    resource_id: [],
    date_from: '',
    date_to: '',
  },
}));
