import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseCarsExaminationColumns = [
  {
    label: i18n.t('base.cars.service.field.dateExam'),
    field: 'date',
    showInDetails: true,
  },
  {
    label: i18n.t('base.global.field.status'),
    field: 'status',
  },
  {
    label: i18n.t('base.cars.service.field.resource'),
    field: 'resource',
  },
  {
    label: i18n.t('base.cars.service.field.reporter'),
    field: 'reporter',
  },
  {
    label: i18n.t('base.cars.service.field.acceptor'),
    field: 'acceptor',
  },
];

export default ref(createTable('base/examination', baseCarsExaminationColumns, {
  filters: {
    date_from: '',
    date_to: '',
    service_types: [],
    status_ids: [],
    accepting_ids: [],
    reporting_ids: [],
    resource_ids: [],
  },
}));
