var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "base-machines-scheduler"
  }, [_c('vs-scheduler', {
    attrs: {
      "events": _vm.events,
      "isLoading": _vm.isLoading,
      "columnScopeDays": _vm.columnScopeDays
    },
    on: {
      "event:create": _vm.createEvent,
      "event:update": _vm.updateEvent,
      "event:click": _vm.onEventClick,
      "schedule:change-scope": _vm.fetchEvents,
      "scope:toggle": _vm.toggleDayScope
    }
  }), _vm.overwrittenEvents.length ? _c('div', [_vm._l(_vm.overwrittenEvents, function (e) {
    return _c('div', {
      key: e.id,
      staticClass: "change-summary"
    }, [_vm._v(" " + _vm._s(e.vehicle.name) + " - " + _vm._s(e.name) + " "), _c('div', {
      staticClass: "old"
    }, [_vm._v(" " + _vm._s(e.old_date_from) + " - " + _vm._s(e.old_date_to) + " ")]), _c('div', {
      staticClass: "new"
    }, [_vm._v(" " + _vm._s(e.date_from) + " - " + _vm._s(e.date_to) + " ")]), _c('vs-button', {
      attrs: {
        "vs-variant": "link"
      },
      on: {
        "click": function () {
          return _vm.deleteUnsaved(e.id);
        }
      }
    }, [_vm._v(" Przywróć ")])], 1);
  }), _c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.updateManyResources
    }
  }, [_vm._v(" Zapisz zmiany ")])], 2) : _vm._e(), _c('investments-assign-resource-modal', {
    attrs: {
      "showInvestmentPicker": ""
    },
    on: {
      "success": function () {
        return _vm.fetchEvents();
      }
    }
  }), _c('investments-edit-resource-modal', {
    attrs: {
      "showInvestmentPicker": ""
    },
    on: {
      "success": function () {
        return _vm.fetchEvents();
      },
      "deleted": function () {
        return _vm.fetchEvents();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }