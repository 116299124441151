var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data wyjazdu od"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową"
    },
    model: {
      value: _vm.filters.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_from", $$v);
      },
      expression: "filters.date_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data wyjazdu do"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową"
    },
    model: {
      value: _vm.filters.date_to,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_to", $$v);
      },
      expression: "filters.date_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Typ jazdy"
    }
  }, [_c('base-work-type-picker', {
    attrs: {
      "multiple": "",
      "optionFilter": function (w) {
        return w.resource_types.find(function (t) {
          return t.slug === 'car';
        });
      }
    },
    model: {
      value: _vm.filters.work_type_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "work_type_ids", $$v);
      },
      expression: "filters.work_type_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kierowca"
    }
  }, [_c('cadre-employee-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.operator_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "operator_ids", $$v);
      },
      expression: "filters.operator_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.resourceType.car')
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "reduce": function (tool) {
        return tool.id;
      },
      "multiple": "",
      "excluded": _vm.shouldResourceBeExcluded
    },
    model: {
      value: _vm.filters.resource_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "resource_ids", $$v);
      },
      expression: "filters.resource_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }