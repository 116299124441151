<template>
  <div class="base-cars-list-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.cars.cars"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'name'">
            {{ row.name }}
          </span>
          <span v-if="column.field === 'user'">
            <cadre-employee-label
              withAvatar
              nameOnly
              :data="row.employee"
              v-if="row.employee"
            />
          </span>
          <span v-if="column.field === 'status'">
            <status-badge :item="row.status" />
          </span>

          <span v-if="column.field === 'inspection'">
            <template v-if="row.newest_registration">
              <examination-badge
                alwaysShowDate
                :date="row.newest_registration.next_date"
                :expired="isInspectionExpired(
                  row.newest_registration,
                  row.meter_status?.status ?? 0
                )"
                :aboutToExpire="isInspectionAboutToExpire(
                  row.newest_registration,
                  row.meter_status?.status ?? 0,
                  'car'
                )"
              />
            </template>
          </span>

          <span v-if="column.field === 'service'">
            <template v-if="row.newest_inspection
              && (row.newest_inspection.next_date || row.newest_inspection.next_meter_status)">
              <examination-badge
                alwaysShowDate
                :date="row.newest_inspection.next_date"
                :expired="isInspectionExpired(
                  row.newest_inspection,
                  row.meter_status?.status ?? 0
                )"
                :aboutToExpire="isInspectionAboutToExpire(
                  row.newest_inspection,
                  row.meter_status?.status ?? 0,
                  'car'
                )"
              />
            </template>
            <template v-else-if="row.newest_inspection && row.newest_inspection.date">
              {{ $tc(
                'base.service.details.daysSince',
                Math.abs(daysFromToday(row.newest_inspection.date, true))
              ) }}
            </template>
          </span>

          <span v-if="column.field === 'mileage'">
            <span v-if="row.meter_status?.status">
              {{ row.meter_status.status }} km
            </span>
          </span>
        </template>
      </vs-table>
    </authorization-guard>
  </div>
</template>

<script>

import VsTable from '@/components/ui/vs-table/Table.vue';
import baseCarsTable from '@/hooks/tables/base/cars';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useBaseCars from '@/hooks/base/cars/useBaseCars';
import { isInspectionAboutToExpire, isInspectionExpired } from '@/utils/base/inspection';
import { daysFromToday } from '@/libs/date-formatter';
import CadreEmployeeLabel from '../../cadre/ui/CadreEmployeeLabel.vue';
import ExaminationBadge from '../../cadre/ui/ExaminationBadge.vue';

export default {
  name: 'MachinesListTable',
  components: {
    VsTable,
    AuthorizationGuard,
    StatusBadge,
    CadreEmployeeLabel,
    ExaminationBadge,
  },
  setup() {
    const { getInspectionDateComponent } = useBaseCars();
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = baseCarsTable.value;

    fetchData();

    const { modalEditResource } = useBaseModals();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditResource.value.open({ fetch: true, id: obj.id });
        },
      },
    ];

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      getInspectionDateComponent,
      isInspectionExpired,
      isInspectionAboutToExpire,
      daysFromToday,
    };
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'base.cars.details', params: { id: data.row.id } });
    },
  },
};
</script>
