var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalPreviewInsurance
    }
  }, [_c('base-cars-insurance-card', {
    attrs: {
      "prefetchedInsurance": _vm.insurance
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }