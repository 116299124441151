<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Data badania od"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="filters.date_from"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data badania do"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="filters.date_to"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Status"
            >
            <base-service-status-picker
              multiple
              v-model="filters.status_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Osoba akceptująca"
            >
            <core-user-picker withDeleted multiple v-model="filters.accepting_ids" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Osoba zgłaszająca"
            >
            <core-user-picker withDeleted multiple v-model="filters.reporting_ids" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Zasób"
            >
            <base-resource-picker
              multiple
              v-model="filters.resource_ids"
              :excluded="shouldResourceBeExcluded"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import baseCarsExaminationColumns from '@/hooks/tables/base/cars/examination';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import BaseServiceStatusPicker from '@/components/module/base/BaseServiceStatusPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import { useRouter } from '@/@core/utils/utils';

export default {
  name: 'BaseCarsServiceFiltration',
  setup() {
    const {
      table, filters, clearAllFilters, fetchData,
    } = baseCarsExaminationColumns.value;

    const applicableResourceTypes = ['car'];
    const shouldResourceBeExcluded = (resource) => {
      const hasAppropriateType = applicableResourceTypes.includes(resource.type?.slug);
      if (!hasAppropriateType) return true;

      return false;
    };

    baseCarsExaminationColumns.value.shouldUpdateTableOnFilterChange = false;

    const { route } = useRouter();
    const resourceId = route.value.params?.resourceId;
    if (resourceId) {
      filters.resource_ids = [resourceId];
    }

    setTimeout(() => {
      baseCarsExaminationColumns.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    return {
      filters,
      clearAllFilters,
      table,
      shouldResourceBeExcluded,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    VsDatepicker,
    BaseServiceStatusPicker,
    CoreUserPicker,
    BaseResourcePicker,
  },
};
</script>
