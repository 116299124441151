<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Data od"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="filters.date_from"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data do"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="filters.date_to"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Towarzystwo ubezpieczeniowe"
            >
            <crm-provider-picker
              multiple
              v-model="filters.insurancy_company_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Samochód"
            >
            <base-resource-picker
              multiple
              v-model="filters.resource_id"
              :excluded="(resource) => resource?.type?.slug !== 'car'"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import baseCarsInsuranceTable from '@/hooks/tables/base/cars/insurance';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import { useRouter } from '@/@core/utils/utils';

export default {
  name: 'BaseCarsInsuranceFiltration',
  setup() {
    const {
      table,
      filters,
      clearAllFilters,
      fetchData,
    } = baseCarsInsuranceTable.value;

    const { route } = useRouter();
    const getIdFilterFromRoute = (param) => {
      const routeFilter = route.value.params?.[param];
      if (!routeFilter) return [];

      return [Number(routeFilter)];
    };

    baseCarsInsuranceTable.value.shouldUpdateTableOnFilterChange = false;

    // filters are defined as a ref, why can't we access `value` property?
    // FIXME: find out why this happens
    filters.resource_id = getIdFilterFromRoute('carId');

    // FIXME: variable is also defined as a ref, but can't access
    // the `value` property. Also get rid of this timeout workaround
    setTimeout(() => {
      baseCarsInsuranceTable.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,
    VsDatepicker,
    CrmProviderPicker,
    BaseResourcePicker,
  },
};
</script>
