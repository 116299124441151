var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-insurance-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.cars.insurance.insurance,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$insurance_compan;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'car' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource,
            "type": "car"
          }
        })], 1) : _vm._e(), column.field === 'date_from' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.insurance(row.date_from)) + " ")]) : _vm._e(), column.field === 'date_to' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.insurance(row.date_to)) + " ")]) : _vm._e(), column.field === 'company' ? _c('span', [_c('crm-company-label', {
          attrs: {
            "integrated": (_row$insurance_compan = row.insurance_company) === null || _row$insurance_compan === void 0 ? void 0 : _row$insurance_compan.company,
            "companyType": "provider"
          }
        })], 1) : _vm._e(), column.field === 'police' ? _c('span', [_vm._v(" " + _vm._s(row.policy_number) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('base-insurance-preview-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }