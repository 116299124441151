var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-list-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.cars.cars,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$meter_status$sta, _row$meter_status, _row$meter_status$sta2, _row$meter_status2, _row$meter_status$sta3, _row$meter_status3, _row$meter_status$sta4, _row$meter_status4, _row$meter_status5;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'user' ? _c('span', [row.employee ? _c('cadre-employee-label', {
          attrs: {
            "withAvatar": "",
            "nameOnly": "",
            "data": row.employee
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'status' ? _c('span', [_c('status-badge', {
          attrs: {
            "item": row.status
          }
        })], 1) : _vm._e(), column.field === 'inspection' ? _c('span', [row.newest_registration ? [_c('examination-badge', {
          attrs: {
            "alwaysShowDate": "",
            "date": row.newest_registration.next_date,
            "expired": _vm.isInspectionExpired(row.newest_registration, (_row$meter_status$sta = (_row$meter_status = row.meter_status) === null || _row$meter_status === void 0 ? void 0 : _row$meter_status.status) !== null && _row$meter_status$sta !== void 0 ? _row$meter_status$sta : 0),
            "aboutToExpire": _vm.isInspectionAboutToExpire(row.newest_registration, (_row$meter_status$sta2 = (_row$meter_status2 = row.meter_status) === null || _row$meter_status2 === void 0 ? void 0 : _row$meter_status2.status) !== null && _row$meter_status$sta2 !== void 0 ? _row$meter_status$sta2 : 0, 'car')
          }
        })] : _vm._e()], 2) : _vm._e(), column.field === 'service' ? _c('span', [row.newest_inspection && (row.newest_inspection.next_date || row.newest_inspection.next_meter_status) ? [_c('examination-badge', {
          attrs: {
            "alwaysShowDate": "",
            "date": row.newest_inspection.next_date,
            "expired": _vm.isInspectionExpired(row.newest_inspection, (_row$meter_status$sta3 = (_row$meter_status3 = row.meter_status) === null || _row$meter_status3 === void 0 ? void 0 : _row$meter_status3.status) !== null && _row$meter_status$sta3 !== void 0 ? _row$meter_status$sta3 : 0),
            "aboutToExpire": _vm.isInspectionAboutToExpire(row.newest_inspection, (_row$meter_status$sta4 = (_row$meter_status4 = row.meter_status) === null || _row$meter_status4 === void 0 ? void 0 : _row$meter_status4.status) !== null && _row$meter_status$sta4 !== void 0 ? _row$meter_status$sta4 : 0, 'car')
          }
        })] : row.newest_inspection && row.newest_inspection.date ? [_vm._v(" " + _vm._s(_vm.$tc('base.service.details.daysSince', Math.abs(_vm.daysFromToday(row.newest_inspection.date, true)))) + " ")] : _vm._e()], 2) : _vm._e(), column.field === 'mileage' ? _c('span', [(_row$meter_status5 = row.meter_status) !== null && _row$meter_status5 !== void 0 && _row$meter_status5.status ? _c('span', [_vm._v(" " + _vm._s(row.meter_status.status) + " km ")]) : _vm._e()]) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }