<template>
  <b-card class="base-machines-scheduler">
    <vs-scheduler
      :events="events"
      :isLoading="isLoading"
      :columnScopeDays="columnScopeDays"

      @event:create="createEvent"
      @event:update="updateEvent"
      @event:click="onEventClick"

      @schedule:change-scope="fetchEvents"
      @scope:toggle="toggleDayScope"
    />

    <div v-if="overwrittenEvents.length">
      <div class="change-summary" v-for="e in overwrittenEvents" :key="e.id">
        {{ e.vehicle.name }} - {{ e.name }}

        <div class="old">
          {{ e.old_date_from }} - {{ e.old_date_to }}
        </div>

        <div class="new">
          {{ e.date_from }} - {{ e.date_to }}
        </div>

        <vs-button @click="() => deleteUnsaved(e.id)" vs-variant="link">
          Przywróć
        </vs-button>

      </div>
      <vs-button vs-variant="primary" @click="updateManyResources">
        Zapisz zmiany
      </vs-button>
    </div>

    <investments-assign-resource-modal
      showInvestmentPicker
      @success="() => fetchEvents()"
    />

    <investments-edit-resource-modal
      showInvestmentPicker
      @success="() => fetchEvents()"
      @deleted="() => fetchEvents()"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VsScheduler from '@/components/ui/vs-scheduler/VsScheduler.vue';
import InvestmentsAssignResourceModal from '@/components/views/investments/modals/InvestmentsAssignResourceModal.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { computed, ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import formatDate, { addDays } from '@/libs/date-formatter';
import InvestmentsEditResourceModal from '@/components/views/investments/modals/InvestmentsEditResourceModal.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { UpdateManyResourceSuccess, UpdateManyResourceError } from '@/libs/toasts/feedback/module/investments';
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  name: 'BaseMachinesScheduler',
  props: {
    type: {
      type: String,
      default: 'vehicle',
    },
  },
  setup(props) {
    const resources = ref([]);
    const isLoading = ref(false);
    const latestRange = ref({ start: null, end: null });
    const columnScopeDays = ref(1);
    const toggleDayScope = () => {
      columnScopeDays.value = (columnScopeDays.value === 1 ? 7 : 1);
    };

    const overwrittenEvents = ref([]);

    const fetchEvents = (startDate = latestRange.value.start, endDate = latestRange.value.end) => {
      isLoading.value = true;
      latestRange.value = { start: startDate, end: endDate };

      useBaseApi()
        .fetchScheduleEntries(startDate, endDate, props.type)
        .then(({ data }) => {
          resources.value = data.data.resources ?? [];
        })
        .finally(() => {
          setTimeout(() => {
            isLoading.value = false;
          }, 300);
          overwrittenEvents.value = [];
        });
    };

    fetchEvents(addDays(Date.now(), 0), addDays(Date.now(), 6 * columnScopeDays.value));

    const events = computed(() => {
      const vehicles = resources.value
        .map((a) => ({
          vehicle: { id: a.id, resource_id: a.id, name: a.name },
          events: a.investment_resources
            .filter((r) => r.resource_data?.scheduler)
            .map((investmentRelation) => {
              const { scheduler } = investmentRelation.resource_data;
              const overwritten = overwrittenEvents.value
                .find((t) => t.id === investmentRelation?.id) ?? {};

              return {
                id: investmentRelation?.id,
                old_date_from: formatDate.standard(scheduler?.start_date),
                old_date_to: formatDate.standard(scheduler?.end_date),
                date_from: formatDate.standard(scheduler?.start_date),
                date_to: formatDate.standard(scheduler?.end_date),
                days: investmentRelation.resource_data?.days_number,
                name: investmentRelation?.investment?.name,
                investment_id: investmentRelation?.investment?.id,
                color: investmentRelation?.technology?.color,
                category: investmentRelation?.technology?.name,
                technology_id: investmentRelation?.technology?.id,
                ...overwritten,
              };
            }),
        }));

      return vehicles;
    });
    const { investmentAssignResourceModal, investmentEditResourceModal } = useInvestmentsStore();

    const openAssignModal = () => {
      investmentAssignResourceModal.value.data = {
        base_resource_id: 1,
        base_vehicle_id: 1,
      };
      investmentAssignResourceModal.value.visible = true;
    };

    const createEvent = (event) => {
      investmentAssignResourceModal.value.data = {
        base_resource_id: event.resource_id,
        base_vehicle_id: event.id,
      };
      investmentAssignResourceModal.value.visible = true;
    };

    const onEventClick = (event) => {
      investmentEditResourceModal.value.data = {
        id: event.id,
        resource_data: {
          days_number: event.days,
          scheduler: {
            start_date: event.date_from,
            end_date: event.date_to,
          },
        },
        base_resource_id: event.vehicle.resource_id,
        base_technology_id: event.technology_id,
        base_vehicle_id: event.vehicle.id,
        resource: { name: event.vehicle.name },
        investment_id: event.investment_id,
      };
      investmentEditResourceModal.value.visible = true;
    };

    const updateEvent = (event) => {
      if (!event || !event.id) return;

      overwrittenEvents.value = [
        ...overwrittenEvents.value.filter((r) => r.id !== event.id),
        { ...event, overwritten: true },
      ];
    };

    const updateManyResources = () => {
      const ready = overwrittenEvents.value.map((t) => ({
        id: t.id,
        base_resource_id: t.vehicle.id,
        investment_id: t.investment_id,
        resource_data: {
          days_number: t.days,
          scheduler: {
            start_date: t.date_from,
            end_date: t.date_to,
          },
        },
      }));

      useInvestmentsApi()
        .updateManyResources({
          investment_base_resources: ready,
        })
        .then(() => {
          showToast(UpdateManyResourceSuccess);
          fetchEvents();
        })
        .catch(() => {
          showToast(UpdateManyResourceError);
        });
    };

    const deleteUnsaved = (eventId) => {
      overwrittenEvents.value = overwrittenEvents.value.filter((t) => t.id !== eventId);
    };

    return {
      fetchEvents,
      openAssignModal,
      createEvent,
      events,
      isLoading,
      latestRange,
      onEventClick,
      updateEvent,
      overwrittenEvents,
      deleteUnsaved,
      updateManyResources,
      columnScopeDays,
      toggleDayScope,
    };
  },
  components: {
    BCard,
    VsScheduler,
    InvestmentsAssignResourceModal,
    InvestmentsEditResourceModal,
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.change-summary
  display: flex
  align-items: center
  gap: 15px

  .old
    color: red

  .new
    color: green
</style>
