<template>
  <div class="investments-assign-resource-form">
    <vs-form
      v-model="resource"
      :errors="errors"
      :i18nNamespace="i18nKey"
    >

      <vs-form-group
        field="investment_id"
        v-if="showInvestmentPicker"
        >
        <investments-investment-picker
          :reduce="i => i.id"
          v-model="resource.investment_id"
        />
      </vs-form-group>

      <vs-form-group
        field="base_resource_id"
        integration="base_resource_relation"
        v-if="!assignedResource"
        >
        <base-resource-picker
          v-model="resource.base_resource_id"
          :labelProps="{ withType: true }"
          @pick="(resource) => selectedResource = resource"
        />
      </vs-form-group>

      <vs-form-group
        field="base_resource_id"
        integration="base_resource_relation"
        :forcedLabel="assignedResource.resourceType === 'vehicle' ? 'Maszyna' : 'Sprzęt'"
        v-else
        >
        <div
          class="assigned-resource"
          >
          <base-resource-label
            :resource="assignedResource"
            :id="assignedResource.typedResourceId"
            :type="assignedResource.resourceType"
          />
        </div>
      </vs-form-group>

      <vs-form-group
        field="base_technology_id"
        integration="base_technology_relation"
        >
        <base-technology-picker
          v-model="resource.base_technology_id"
        />
      </vs-form-group>

      <vs-form-group
        field="resource_data.scheduler.start_date"
        >
        <vs-datepicker
          placeholder="Wybierz datę"
          v-model="resource.resource_data.scheduler.start_date"
        />
      </vs-form-group>

      <vs-form-group
        field="resource_data.scheduler.end_date"
        >
        <vs-datepicker
          placeholder="Wybierz datę"
          v-model="resource.resource_data.scheduler.end_date"
        />
      </vs-form-group>

      <vs-form-group
        field="resource_data.days_number"
        >
        <b-form-input
          :placeholder="$t(`${i18nKey}.resource_data.days_number`)"
          v-model="resource.resource_data.days_number"
        />
      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import InvestmentsInvestmentPicker from '@/components/ui/pickers/investments/InvestmentsInvestmentPicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import { daysCountBetweenDates } from '@/libs/date-formatter';
import BaseTechnologyPicker from '@/components/module/base/BaseTechnologyPicker.vue';
import BaseResourceLabel from '../../base/resource/BaseResourceLabel.vue';

export default {
  name: 'InvestmentsAssignResourceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    assignedResource: {
      type: Object,
    },
    showInvestmentPicker: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const resource = computed(() => props.value);
    const errors = ref({});

    const startDate = computed(() => resource.value.resource_data?.scheduler?.start_date);
    const endDate = computed(() => resource.value.resource_data?.scheduler?.end_date);

    watch([
      () => startDate.value,
      () => endDate.value,
    ], () => {
      const daysCount = daysCountBetweenDates(startDate.value, endDate.value, [0, 6]);
      resource.value.resource_data.days_number = daysCount;
    });

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    const selectedResource = ref(null);

    const i18nKey = 'investments.form.investment.tab.resource.field';

    return {
      resource,
      selectedResource,
      i18nKey,
      errors,
    };
  },
  components: {
    BFormInput,
    VsForm,
    VsFormGroup,
    BaseResourceLabel,
    InvestmentsInvestmentPicker,
    BaseResourcePicker,
    VsDatepicker,
    BaseTechnologyPicker,
  },
};
</script>

<style lang="sass" scoped>
.assigned-resource
  margin: 7px 0
</style>
