var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    staticClass: "base-insurance-form",
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.base.insurance"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "base_resource_id"
    }
  }, [_vm.resource ? _c('base-resource-label', {
    attrs: {
      "resource": _vm.resource,
      "type": "car"
    }
  }) : _c('base-resource-picker', {
    attrs: {
      "excluded": function (r) {
        var _r$type;
        return ((_r$type = r.type) === null || _r$type === void 0 ? void 0 : _r$type.slug) !== 'car';
      }
    },
    model: {
      value: _vm.insurance.base_resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.insurance, "base_resource_id", $$v);
      },
      expression: "insurance.base_resource_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_provider_id"
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function () {
        return [_c('div', {
          staticClass: "nested-form-groups"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "date_from"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.insurance.date_from,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "date_from", $$v);
            },
            expression: "insurance.date_from"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "date_to"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.insurance.date_to,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "date_to", $$v);
            },
            expression: "insurance.date_to"
          }
        })], 1)], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "policy_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "000000000"
          },
          model: {
            value: _vm.insurance.policy_number,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "policy_number", $$v);
            },
            expression: "insurance.policy_number"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "phone_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "000 000 000"
          },
          model: {
            value: _vm.insurance.phone_number,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "phone_number", $$v);
            },
            expression: "insurance.phone_number"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "coverage"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "placeholder": "Np. OC, AC"
          },
          model: {
            value: _vm.insurance.coverage,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "coverage", $$v);
            },
            expression: "insurance.coverage"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "file"
          }
        }, [!_vm.insurance.id ? _c('vs-file', {
          model: {
            value: _vm.insurance.file,
            callback: function ($$v) {
              _vm.$set(_vm.insurance, "file", $$v);
            },
            expression: "insurance.file"
          }
        }) : _c('file-dialog', {
          attrs: {
            "filename": _vm.insurance._filename
          },
          model: {
            value: _vm.insurance,
            callback: function ($$v) {
              _vm.insurance = $$v;
            },
            expression: "insurance"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('crm-provider-picker', {
    model: {
      value: _vm.insurance.crm_provider_id,
      callback: function ($$v) {
        _vm.$set(_vm.insurance, "crm_provider_id", $$v);
      },
      expression: "insurance.crm_provider_id"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }