<template>
  <div class="base-cars-service">

    <div class="listWrapper" :class="{ fullWidth: !detailsVisible }">
      <b-card>
        <authorization-guard
          permission="base.resource_service.index"
          silent
        >
          <base-cars-service-filtration
            :resource-type="backType"
          />
        </authorization-guard>

        <vs-button
          vs-variant="primary"
          @click="openCreateModal"
        >
          + Dodaj serwis
        </vs-button>
        <br />
        <br />

        <authorization-guard
          permission="base.resource_service.index"
        >
          <base-cars-service-table
            @delete="deleteService"
            @openServiceDetails="openServiceDetails"
          />
        </authorization-guard>
      </b-card>
    </div>

    <div class="detailsColumn" :class="{ visible: detailsVisible }">
      <base-service-details
        v-if="detailsVisible"
        @delete="deleteService"
        @close="closeServiceDetails"
      />
    </div>

    <base-service-create-modal
      @ok="fetchData"
      :resourceTypes="[backType]"
    />

    <base-service-edit-modal
      @ok="fetchData"
      :resourceTypes="[backType]"
    />

  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseCarsServiceFiltration from '@/components/views/base/cars/BaseCarsServiceFiltration.vue';
import BaseCarsServiceTable from '@/components/views/base/cars/BaseCarsServiceTable.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseServiceCreateModal from '@/components/views/base/service/modals/BaseServiceCreateModal.vue';
import { useRouter } from '@/@core/utils/utils';
import { computed } from 'vue';
import BaseServiceDetails from '@/components/views/base/service/BaseServiceDetails.vue';
import baseServiceTable from '@/hooks/tables/base/cars/service';
import BaseServiceEditModal from '@/components/views/base/service/modals/BaseServiceEditModal.vue';
import showToast from '@/libs/toasts';
import { DeleteServiceError, DeleteServiceSuccess } from '@/libs/toasts/feedback/module/base';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import useBaseApi from '@/hooks/base/useBaseApi';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'BaseCarsService',
  props: {
    type: {
      type: String,
    },
  },
  setup(props) {
    const { modalCreateService } = useBaseModals();
    const openCreateModal = () => {
      modalCreateService.value.open();
    };

    const { route, router } = useRouter();
    const detailsVisible = computed(() => route.value
      .name === `base.${props.type}.service.details`);

    const { fetchData } = baseServiceTable.value;

    const deleteService = async (service) => {
      const confirmation = await deleteModal({
        title: i18n.t('base.service.modal.delete.title'),
        text: i18n.t('base.service.modal.delete.text'),
      });

      if (!confirmation) return false;

      useBaseApi()
        .deleteService(service.id)
        .then(() => {
          showToast(DeleteServiceSuccess);
          if (route.value.params?.id === service.id) {
            router.push({ name: `base.${props.type}.service` });
          }
          fetchData();
        })
        .catch(() => {
          showToast(DeleteServiceError);
        });

      return true;
    };

    const frontBackMap = {
      machines: 'vehicle',
      equipment: 'tool',
      cars: 'car',
    };
    const backType = computed(() => frontBackMap[props.type]);

    const openServiceDetails = (service) => {
      router.push({ name: `base.${props.type}.service.details`, params: { id: service.id } });
    };

    const closeServiceDetails = () => {
      router.push({ name: `base.${props.type}.service` });
    };

    return {
      openCreateModal,
      detailsVisible,
      fetchData,
      deleteService,
      frontBackMap,
      backType,
      openServiceDetails,
      closeServiceDetails,
    };
  },
  components: {
    BCard,
    BaseCarsServiceFiltration,
    BaseCarsServiceTable,
    VsButton,
    BaseServiceCreateModal,
    BaseServiceDetails,
    BaseServiceEditModal,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>

$details-width: 40%

.base-cars-service
  display: flex

  .listWrapper
    width: calc(100% - #{$details-width})
    transition: 0.5s width ease

    &.fullWidth
      width: 100%

  .detailsColumn
    width: $details-width
    transition: 0.5s all ease-in-out
    position: absolute
    pointer-events: none
    opacity: 0
    padding-left: 20px
    height: 0

    &.visible
      opacity: 1
      animation: relativeDelay .1s ease forwards
      pointer-events: auto
      height: auto

      @keyframes relativeDelay
        from
          position: absolute
        to
          position: relative

@media screen and (max-width: 1000px)
  .tasks
    display: block

    .listWrapper
      display: none

      &.fullWidth
        display: block

    .detailsColumn
      width: 100%
      position: relative
      display: block
      padding-left: 0
</style>
