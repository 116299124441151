<template>
  <b-card class="base-cars-insurance">
    <base-cars-insurance-filtration />

    <vs-button vs-variant="primary"
      @click="openCreateModal"
    >
      + Dodaj ubezpieczenie
    </vs-button>
    <br />
    <br />

    <base-cars-insurance-table />

    <base-insurance-create-modal
      @ok="fetchData"
    />
    <base-insurance-edit-modal
      @ok="fetchData"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseCarsInsuranceTable from '@/components/views/base/cars/BaseCarsInsuranceTable.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import BaseInsuranceCreateModal from '@/components/views/base/insurance/modals/BaseInsuranceCreateModal.vue';
import baseCarsInsuranceTable from '@/hooks/tables/base/cars/insurance';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseInsuranceEditModal from '@/components/views/base/insurance/modals/BaseInsuranceEditModal.vue';
import BaseCarsInsuranceFiltration from '@/components/views/base/cars/BaseCarsInsuranceFiltration.vue';

export default {
  name: 'BaseCarsInsurance',
  setup() {
    const { fetchData } = baseCarsInsuranceTable.value;
    const { modalCreateInsurance } = useBaseModals();
    const openCreateModal = () => {
      modalCreateInsurance.value.open();
    };

    return { fetchData, openCreateModal };
  },
  components: {
    BCard,
    BaseCarsInsuranceTable,
    VsButton,
    BaseInsuranceCreateModal,
    BaseInsuranceEditModal,
    BaseCarsInsuranceFiltration,
  },
};
</script>
