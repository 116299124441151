<template>
  <div class="investments-edit-resource-modal">
    <b-modal
      id="modal-investments-edit-resource"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.resource.edit.title')"

      v-model="investmentEditResourceModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.resource.edit.loading')"
        v-if="isLoading"
      />

      <investments-assign-resource-form
        v-model="fields"
        :feedback="feedback"
        :assignedResource="assignedResource"
        :showInvestmentPicker="showInvestmentPicker"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.resource.create.cancel')"
          :text-ok="$t('investments.modal.resource.create.confirm')"

          @ok="editResource"
          @cancel="investmentEditResourceModal.visible = false"
        >
          <template #left>
            <vs-button
              vs-variant="light"
              @click="deleteResource"
            >
              <feather-icon icon="Trash2Icon" />
            </vs-button>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import {
  DeleteResourceError, DeleteResourceSuccess, EditResourceError, EditResourceSuccess,
} from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import InvestmentsAssignResourceForm from '../forms/InvestmentsAssignResourceForm.vue';

export default {
  name: 'InvestmentsAssignResourceModal',
  emits: ['success', 'fail', 'deleted'],
  props: {
    showInvestmentPicker: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { investmentEditResourceModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const assignedResource = ref(null);

    const {
      fields, feedback, resetFields, assignFields,
      assignServerSideErrors,
    } = useValidatedForm({
      id: '',
      investment_id: '',
      base_resource_id: 'required|integer',
      base_technology_id: 'required|integer',
      resource_data: {
        days_number: 'required|integer',
        scheduler: {
          start_date: 'required',
          end_date: 'required|date_after:@resource_data.scheduler.start_date',
        },
      },
    }, 'investments.form.investment.tab.resource.field');

    watch(() => investmentEditResourceModal.value.visible, () => {
      const { data } = investmentEditResourceModal.value;
      if (data && Object.keys(data).length === 0) return;

      resetFields();
      const editingValues = { ...investmentEditResourceModal.value.data };
      assignedResource.value = {
        ...editingValues.resource,
        typedResourceId: editingValues.base_vehicle_id ?? editingValues.base_tool_id,
        resourceType: editingValues.base_vehicle_id ? 'vehicle' : 'tool',
      };

      assignFields({
        ...editingValues,
      });

      investmentEditResourceModal.value.data = {};
    });

    const editResource = async () => {
      isLoading.value = true;

      useInvestmentsApi()
        .updateAssignedResource(fields.value.id, {
          ...fields.value,
        })
        .then(({ data }) => {
          showToast(EditResourceSuccess, { name: data.data?.base_vehicle?.resource?.name });

          investmentEditResourceModal.value.visible = false;
          emit('success', { ...data.data });

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          emit('fail');
          assignServerSideErrors(response?.data?.errors);
          showToast(EditResourceError);
          isLoading.value = false;
        });
    };

    const deleteResource = async () => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.resource.delete.title'),
        text: i18n.t('investments.modal.resource.delete.text', {
          name: assignedResource.value?.name,
        }),
      });

      if (!confirmation) return false;

      useInvestmentsApi()
        .deleteResource(fields.value.id)
        .then(() => {
          showToast(DeleteResourceSuccess, { name: assignedResource.value?.name });
          investmentEditResourceModal.value.visible = false;
          emit('deleted');
        })
        .catch(() => {
          showToast(DeleteResourceError);
        });

      return true;
    };

    return {
      fields,
      feedback,
      investmentEditResourceModal,
      isLoading,
      editResource,
      assignedResource,
      deleteResource,
    };
  },
  components: {
    VsLoader,
    InvestmentsAssignResourceForm,
    VsModalFooter,
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.buttons
  margin-left: auto

.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
