<template>
  <vs-modal
    :modalInstance="modalEditReport"
    @ok="createReport"
  >
    <resource-report-form
      v-model="fields"
      :feedback="feedback"
      :refuel="reportFuel"
      :resourceEditable="false"
    />
  </vs-modal>
</template>

<script>
import useBaseModals from '@/hooks/base/useBaseModals';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { UpdateMachineReportError, UpdateMachineReportSuccess } from '@/libs/toasts/feedback/module/base';
import { ref } from 'vue';
import ResourceReportForm from '../../resource/forms/ResourceReportForm.vue';

export default {
  name: 'BaseResourceReportCreateModal',
  emits: ['ok'],
  props: {
    type: {
      type: String,
    },
    table: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const { modalEditReport } = useBaseModals();

    const {
      fields,
      feedback,
      assignFields,
      resetFields,
      areAllFieldsValid,
      nonEmptyFields,
      errors,
    } = useValidatedForm({
      id: 'required|integer',
      base_resource_id: 'required|integer',
      amount_fuel: 'required_or:@base_work_type_id|integer',
      base_work_type_id: 'required_or:@amount_fuel',
      comment: '',
      purpose: '',
      route_description: '',
      meter_status: 'required|integer',
      operator_id: 'required|integer',
      report_date: 'required',
      reporter_id: 'required|integer',
    });

    const reportFuel = ref(false);
    modalEditReport.value.on({
      open(data) {
        resetFields();
        reportFuel.value = !!data.amount_fuel;
        assignFields(data);
      },
      close: () => {
        modalEditReport.value.cleanData();
        resetFields();
      },
    });

    const createReport = async () => {
      if (!(await areAllFieldsValid())) return;

      modalEditReport.value.isLoading = true;

      useBaseApi()
        .updateMachineReport({ ...nonEmptyFields.value })
        .then(({ data }) => {
          showToast(UpdateMachineReportSuccess, { name: '' });
          modalEditReport.value.close();
          emit('ok', { ...data.data, reportedFuel: reportFuel.value });
        })
        .catch(({ response }) => {
          errors.serverSide.value = response.data?.errors;
          showToast(UpdateMachineReportError, { name: '' });
        })
        .finally(() => {
          modalEditReport.value.isLoading = false;
        });
    };

    return {
      modalEditReport,
      fields,
      feedback,
      createReport,
      errors,
      reportFuel,
    };
  },
  components: {
    ResourceReportForm, VsModal,
  },
};

</script>
