<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Data wyjazdu od"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="filters.date_from"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data wyjazdu do"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="filters.date_to"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Typ jazdy"
            >
            <base-work-type-picker
              multiple
              v-model="filters.work_type_ids"
              :optionFilter="(w) => w.resource_types.find((t) => t.slug === 'car')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Kierowca"
            >
            <cadre-employee-picker withDeleted multiple v-model="filters.operator_ids" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.resourceType.car')"
            >
            <base-resource-picker
              :reduce="(tool) => tool.id"
              multiple
              v-model="filters.resource_ids"
              :excluded="shouldResourceBeExcluded"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import baseCarsMileageTable from '@/hooks/tables/base/cars/mileage';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import { useRouter } from '@/@core/utils/utils';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import BaseWorkTypePicker from '@/components/module/base/BaseWorkTypePicker.vue';

export default {
  name: 'BaseCarsMileageFiltration',
  setup() {
    const {
      table, filters, clearAllFilters, fetchData,
    } = baseCarsMileageTable.value;

    const { route } = useRouter();
    const getIdFilterFromRoute = (param) => {
      const routeFilter = route.value.params?.[param];
      if (!routeFilter) return [];

      return [Number(routeFilter)];
    };

    baseCarsMileageTable.value.shouldUpdateTableOnFilterChange = false;
    filters.resource_ids = getIdFilterFromRoute('machineId');

    setTimeout(() => {
      baseCarsMileageTable.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    const shouldResourceBeExcluded = (resource) => {
      const hasAppropriateType = resource.type?.slug === 'car';
      if (!hasAppropriateType) return true;

      const isReportable = resource.category?.be_reported;
      if (!isReportable) return true;

      return false;
    };

    return {
      filters,
      clearAllFilters,
      table,
      shouldResourceBeExcluded,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    CadreEmployeePicker,
    VsDatepicker,
    BaseResourcePicker,
    BaseWorkTypePicker,
  },
};
</script>
