import { daysFromToday } from '@/libs/date-formatter';

export function daysSinceInspection(inspection) {
  if (!inspection) return 0;
  return daysFromToday(inspection.date, true);
}

export function daysUntilNextInspection(inspection) {
  if (!inspection && !inspection.next_date) return 0;
  return daysFromToday(inspection.next_date, true);
}

export function meterStatusUntilNextInspection(inspection, currentMeterStatus) {
  if (!inspection?.next_meter_status || !currentMeterStatus) return null;

  const diff = inspection.next_meter_status - currentMeterStatus;
  return Math.max(0, diff);
}

export function isInspectionAboutToExpire(inspection, currentMeterStatus, resourceType) {
  const daysUntil = daysUntilNextInspection(inspection);
  const meterStatusUntil = meterStatusUntilNextInspection(inspection, currentMeterStatus);

  if (resourceType === 'car') {
    return daysUntil <= 14
          || (meterStatusUntil !== null
            && meterStatusUntil <= 1000);
  }

  return daysUntil <= 14
          || (meterStatusUntil !== null
            && meterStatusUntil <= 50);
}

export function isInspectionExpired(inspection, currentMeterStatus) {
  const meterStatusUntil = meterStatusUntilNextInspection(inspection, currentMeterStatus);
  return (inspection.next_date && daysUntilNextInspection(inspection) <= 0)
    || (meterStatusUntil !== null && meterStatusUntil <= 0);
}
