var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-service-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.service.service,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.transport(row.date)) + " ")]) : column.field === 'resource' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource
          }
        })], 1) : column.field === 'type' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`base.service.type.${row.type}`)) + " ")]) : column.field === 'status' ? _c('span', [_c('vs-status-badge', {
          attrs: {
            "data": row.status
          }
        })], 1) : column.field === 'reporter' ? _c('span', [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "data": row.reporter
          }
        })], 1) : column.field === 'acceptor' ? _c('span', [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "data": row.accepting
          }
        })], 1) : column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.resource.name) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }