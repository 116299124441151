<template>
  <div class="investments-assign-resource-modal">
    <b-modal
      id="modal-investments-assign-resource"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.resource.create.title')"

      v-model="investmentAssignResourceModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.resource.create.loading')"
        v-if="isLoading"
      />

      <investments-assign-resource-form
        v-model="fields"
        :feedback="feedback"
        :showInvestmentPicker="showInvestmentPicker"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.resource.create.cancel')"
          :text-ok="$t('investments.modal.resource.create.confirm')"

          @ok="assignResource"
          @cancel="investmentAssignResourceModal.visible = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Dodaj kolejny zasób
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { AssignResourceError, AssignResourceSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import InvestmentsAssignResourceForm from '../forms/InvestmentsAssignResourceForm.vue';

export default {
  name: 'InvestmentsAssignResourceModal',
  emits: ['success'],
  props: {
    investmentId: {
      type: [String, Number],
    },
    showInvestmentPicker: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { investmentAssignResourceModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, resetFields, assignServerSideErrors,
      assignFields, fieldDefaults, areAllFieldsValid,
    } = useValidatedForm({
      investment_id: '',
      base_resource_id: 'required|integer',
      base_technology_id: 'required|integer',
      resource_data: {
        days_number: 'required|integer',
        scheduler: {
          start_date: 'required',
          end_date: 'required|date_after:@resource_data.scheduler.start_date',
        },
      },
    }, 'investments.form.investment.tab.resource.field');

    watch(() => investmentAssignResourceModal.value.visible, () => {
      resetFields();
      assignFields({
        investment_id: props.investmentId ?? null,
        ...investmentAssignResourceModal.value.data,
      });
      investmentAssignResourceModal.value.data = {};
    });

    const continueEditing = ref(false);

    const assignResource = async () => {
      if (!(await areAllFieldsValid())) return;
      isLoading.value = true;

      useInvestmentsApi()
        .assignResource({
          ...fields.value,
          investment_id: props.investmentId ?? fields.value.investment_id,
        })
        .then(({ data }) => {
          showToast(AssignResourceSuccess, {
            name: data.data.base_vehicle?.resource?.name,
          });
          emit('success', { ...data.data });

          if (!continueEditing.value) {
            investmentAssignResourceModal.value.visible = false;
          }

          resetFields();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          emit('fail');
          showToast(AssignResourceError);
          isLoading.value = false;
          assignServerSideErrors(response?.data?.errors);
        });
    };

    return {
      fields,
      feedback,
      investmentAssignResourceModal,
      isLoading,
      continueEditing,
      assignResource,
      fieldDefaults,
    };
  },
  components: {
    VsLoader,
    BFormCheckbox,
    InvestmentsAssignResourceForm,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
