var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "base-cars-mileage"
  }, [_c('base-cars-mileage-filtration'), _c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" + Dodaj raport ")]), _c('br'), _c('br'), _c('base-cars-mileage-table'), _c('machines-mth-report-modal', {
    attrs: {
      "type": "car"
    },
    on: {
      "ok": _vm.fetchData
    }
  }), _c('base-resource-report-edit-modal', {
    attrs: {
      "type": "car"
    },
    on: {
      "ok": _vm.fetchData
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }