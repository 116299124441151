import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import { h } from 'vue';
import { isInspectionAboutToExpire, isInspectionExpired } from '@/utils/base/inspection';

export default function useBaseCars() {
  const getInspectionDateComponent = (inspection, currentMeterStatus) => h(ExaminationBadge, {
    props: {
      expired: isInspectionExpired(inspection, currentMeterStatus),
      aboutToExpire: isInspectionAboutToExpire(inspection, currentMeterStatus, 'car'),
    },
  });

  return { getInspectionDateComponent };
}
