<template>
  <component
    :class="`base-cars-insurance-card ${prefetchedInsurance ? 'prefetched' : ''}`"
    :is="prefetchedInsurance ? 'div' : 'widget-card'"
  >

    <vs-loader
      full
      text="Pobieranie danych ubezpieczenia..."
      v-if="isLoading"
    />

    <template
      v-if="insurance"
    >
      <div
        class="insurance-wrapper"
      >
        <div class="left-side">
          <div class="header" v-if="validDaysLeft >= 0">
            <h3>
              Ubezpieczenie ważne
            </h3>
            <h4
              :class="`insurance-info ${validDaysLeft < 3 ? 'expiring' : ''}`"
              v-if="insurance"
            >
              {{ $tc('base.cars.insurance.field.expiringIn', validDaysLeft) }}
              <feather-icon
                icon="AlertCircleIcon"
                v-if="validDaysLeft < 3"
                class="icon-expiring-soon"
                size="18"
              />
            </h4>
          </div>
          <div class="header" v-else>
            <h3>
              Ubezpieczenie nieważne
            </h3>
            <h4 class="insurance-info expired" v-if="insurance">
              {{ $tc('base.cars.insurance.field.expiredSince', -validDaysLeft) }}
              <feather-icon
                icon="AlertCircleIcon"
                class="icon-expired"
                size="18"
              />
            </h4>
          </div>

          <div class="last-insurance">
            <div class="last-insurance-header">
              okres ważności polisy
            </div>
            <div class="last-insurance-date" v-if="insurance">
              <vs-button class="inspection-button" vs-variant="light">
                <feather-icon class="icon" icon="LinkIcon" />
              </vs-button>
              {{ formatDate.insurance(insurance.date_from)}}
              -
              {{ formatDate.insurance(insurance.date_to)}} r.
            </div>
          </div>
        </div>

        <div class="right-side">
          <details-table
            :entries="entries"
            i18n-group="base.cars.insurance.field"
            v-if="insurance"
          >
            <template #header-phone>
              nr tel. Assistance
              <br>
              <br>
            </template>
            <template #value-name>
              <crm-company-label
                :integrated="insurance.insurance_company.company"
                companyType="provider"
              />
            </template>
            <template #value-file>
              <a :href="insurance.file.file_link" target="_blank">
                <feather-icon icon="ExternalLinkIcon" />
                {{ insurance.file.original_name }}
              </a>
            </template>
          </details-table>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="no-insurance">
        <feather-icon icon="InfoIcon" />
        Samochód nie był jeszcze ubezpieczany
      </div>
    </template>

    <template #footer>
      <div class="widget-footer" v-if="!prefetchedInsurance">
        <vs-button
          vs-variant="light"
          @click="openInsuranceModal"
        >
          Aktualizuj
        </vs-button>

        <vs-button
          vs-variant="light"
          @click="openInsuranceHistory"
        >
          Historia
        </vs-button>
      </div>
    </template>

    <base-insurance-create-modal
      :resource="resource"
      @ok="$emit('create:insurance')"
    />
  </component>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import DetailsTable from '@/components/ui/DetailsTable.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import { computed, ref } from 'vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import router from '@/router';
import BaseInsuranceCreateModal from '../../insurance/modals/BaseInsuranceCreateModal.vue';

export default {
  name: 'BaseCarsInsuranceCard',
  emits: ['create:insurance'],
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    prefetchedInsurance: {
      type: Object,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const insurance = computed(() => props.resource.newest_insurance ?? props.prefetchedInsurance);

    const entries = computed(() => ({
      phone: insurance.value?.phone_number,
      name: insurance.value?.crm_provider_id,
      police: insurance.value?.policy_number,
      range: insurance.value?.coverage,
      file: insurance.value?.file,
      // damages: '??? (do podpięcia)',
    }));

    const { modalCreateInsurance } = useBaseModals();
    const openInsuranceModal = () => {
      modalCreateInsurance.value.open({
        base_resource_id: props.resource.id,
      });
    };

    const validDaysLeft = computed(() => daysFromToday(insurance.value?.date_to, true));

    const openInsuranceHistory = () => {
      router.push({ name: 'base.cars.insurance', params: { carId: props.resource.id } });
    };

    return {
      entries,
      openInsuranceModal,
      insurance,
      isLoading,
      formatDate,
      validDaysLeft,
      openInsuranceHistory,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    DetailsTable,
    BaseInsuranceCreateModal,
    CrmCompanyLabel,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.inspection-button
  padding: 4px 8px
  font-size: 9pt
  transition: .2s .2s all ease-in-out
  display: flex
  justify-content: flex-start
  overflow: hidden

.base-cars-insurance-card

  &.prefetched
    padding: 25px

  .no-insurance
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    gap: 10px

  .insurance-info
    display: flex
    align-items: center
    gap: 10px

    &.expiring
      color: var(--vs-warning)

    &.expired
      color: var(--vs-danger)

  .vs-loader
    top: 0

  .insurance-wrapper
    display: flex
    justify-content: space-between

    .right-side
      max-width: 60%

  .last-insurance
    margin-top: 20px

    &-header
      opacity: 0.6

    &-date
      font-size: 12pt
      display: flex
      align-items: center
      gap: 10px

  .widget-footer
    display: flex
    gap: 10px
    align-items: center
</style>
