<template>
  <vs-form
    :errors="feedback"
    i18nNamespace="form.base.insurance"
    class="base-insurance-form"
  >

    <vs-form-group field="base_resource_id">
      <base-resource-label
        :resource="resource"
        type="car"
        v-if="resource"
      />
      <base-resource-picker
        v-model="insurance.base_resource_id"
        :excluded="(r) => r.type?.slug !== 'car'"
        v-else
      />
    </vs-form-group>

    <vs-form-group field="crm_provider_id">
      <crm-provider-picker
        v-model="insurance.crm_provider_id"
      />
      <template #children>
        <div class="nested-form-groups">
          <vs-form-group labelCols="12" cols="6" field="date_from">
            <vs-datepicker
              v-model="insurance.date_from"
            />
          </vs-form-group>

          <vs-form-group labelCols="12" cols="6" field="date_to">
            <vs-datepicker
              v-model="insurance.date_to"
            />
          </vs-form-group>
        </div>

        <vs-form-group labelCols="12" field="policy_number">
          <b-form-input
            v-model="insurance.policy_number"
            placeholder="000000000"
          />
        </vs-form-group>

        <vs-form-group labelCols="12" field="phone_number">
          <b-form-input
            v-model="insurance.phone_number"
            placeholder="000 000 000"
          />
        </vs-form-group>

        <vs-form-group labelCols="12" field="coverage">
          <b-form-textarea
            v-model="insurance.coverage"
            placeholder="Np. OC, AC"
          />
        </vs-form-group>

        <vs-form-group labelCols="12" field="file">
          <vs-file
            v-if="!insurance.id"
            v-model="insurance.file"
          />
          <file-dialog
            v-else
            v-model="insurance"
            :filename="insurance._filename"
          />
        </vs-form-group>

      </template>
    </vs-form-group>

  </vs-form>
</template>

<script>
import { BFormInput, BFormTextarea } from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import { computed } from 'vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import FileDialog from '@/components/ui/file-dialog/FileDialog.vue';
import VsFile from '@/components/ui/vs-file/VsFile.vue';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseInsuranceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    resource: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const insurance = computed({
      get: () => props.value,
      set: (val) => emit('input', { ...val }),
    });

    return { insurance };
  },
  components: {
    BFormInput,
    BFormTextarea,
    VsForm,
    VsFormGroup,
    VsDatepicker,
    BaseResourcePicker,
    CrmProviderPicker,
    BaseResourceLabel,
    FileDialog,
    VsFile,
  },
};
</script>

<style lang="sass" scoped>
.base-insurance-form
  .nested-form-groups
    display: flex
    align-items: flex-start
    gap: 5px

    &::v-deep
      .b-form-group
        width: calc(100% - 5px)
</style>
