<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.model')"
            >
            <base-producer-model-picker
              multiple
              v-model="filters.producer_model_ids"
              :excluded="excludeOptionByType"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.user')"
            >
            <cadre-employee-picker
              withDeleted
              multiple
              v-model="filters.employee_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.group')"
          >
            <base-group-picker
              multiple
              v-model="filters.group_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.status')"
            >
            <base-status-picker
              multiple
              v-model="filters.status_ids"
              :excluded="excludeOptionByType"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import BaseStatusPicker from '@/components/module/base/BaseStatusPicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import baseCarsTable from '@/hooks/tables/base/cars';
import BaseProducerModelPicker from '@/components/module/base/BaseProducerModelPicker.vue';

export default {
  name: 'CarsListFiltration',
  setup() {
    const { table, filters, clearAllFilters } = baseCarsTable.value;

    const excludeOptionByType = (option) => {
      if (option.types) {
        const applicableTypes = option.types.map((type) => type.slug);
        return !applicableTypes.includes('car');
      }
      if (option.type?.slug === 'car') return false;
      return true;
    };

    return {
      filters,
      clearAllFilters,
      table,
      excludeOptionByType,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    CadreEmployeePicker,
    BaseStatusPicker,
    BaseProducerModelPicker,
  },
};
</script>
