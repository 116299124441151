var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-assign-resource-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": _vm.i18nKey
    },
    model: {
      value: _vm.resource,
      callback: function ($$v) {
        _vm.resource = $$v;
      },
      expression: "resource"
    }
  }, [_vm.showInvestmentPicker ? _c('vs-form-group', {
    attrs: {
      "field": "investment_id"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "reduce": function (i) {
        return i.id;
      }
    },
    model: {
      value: _vm.resource.investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "investment_id", $$v);
      },
      expression: "resource.investment_id"
    }
  })], 1) : _vm._e(), !_vm.assignedResource ? _c('vs-form-group', {
    attrs: {
      "field": "base_resource_id",
      "integration": "base_resource_relation"
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "labelProps": {
        withType: true
      }
    },
    on: {
      "pick": function (resource) {
        return _vm.selectedResource = resource;
      }
    },
    model: {
      value: _vm.resource.base_resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_resource_id", $$v);
      },
      expression: "resource.base_resource_id"
    }
  })], 1) : _c('vs-form-group', {
    attrs: {
      "field": "base_resource_id",
      "integration": "base_resource_relation",
      "forcedLabel": _vm.assignedResource.resourceType === 'vehicle' ? 'Maszyna' : 'Sprzęt'
    }
  }, [_c('div', {
    staticClass: "assigned-resource"
  }, [_c('base-resource-label', {
    attrs: {
      "resource": _vm.assignedResource,
      "id": _vm.assignedResource.typedResourceId,
      "type": _vm.assignedResource.resourceType
    }
  })], 1)]), _c('vs-form-group', {
    attrs: {
      "field": "base_technology_id",
      "integration": "base_technology_relation"
    }
  }, [_c('base-technology-picker', {
    model: {
      value: _vm.resource.base_technology_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_technology_id", $$v);
      },
      expression: "resource.base_technology_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "resource_data.scheduler.start_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.resource.resource_data.scheduler.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.resource.resource_data.scheduler, "start_date", $$v);
      },
      expression: "resource.resource_data.scheduler.start_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "resource_data.scheduler.end_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę"
    },
    model: {
      value: _vm.resource.resource_data.scheduler.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.resource.resource_data.scheduler, "end_date", $$v);
      },
      expression: "resource.resource_data.scheduler.end_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "resource_data.days_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t(`${_vm.i18nKey}.resource_data.days_number`)
    },
    model: {
      value: _vm.resource.resource_data.days_number,
      callback: function ($$v) {
        _vm.$set(_vm.resource.resource_data, "days_number", $$v);
      },
      expression: "resource.resource_data.days_number"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }