var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.prefetchedInsurance ? 'div' : 'widget-card', {
    tag: "component",
    class: `base-cars-insurance-card ${_vm.prefetchedInsurance ? 'prefetched' : ''}`,
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [!_vm.prefetchedInsurance ? _c('div', {
          staticClass: "widget-footer"
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openInsuranceModal
          }
        }, [_vm._v(" Aktualizuj ")]), _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openInsuranceHistory
          }
        }, [_vm._v(" Historia ")])], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych ubezpieczenia..."
    }
  }) : _vm._e(), _vm.insurance ? [_c('div', {
    staticClass: "insurance-wrapper"
  }, [_c('div', {
    staticClass: "left-side"
  }, [_vm.validDaysLeft >= 0 ? _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v(" Ubezpieczenie ważne ")]), _vm.insurance ? _c('h4', {
    class: `insurance-info ${_vm.validDaysLeft < 3 ? 'expiring' : ''}`
  }, [_vm._v(" " + _vm._s(_vm.$tc('base.cars.insurance.field.expiringIn', _vm.validDaysLeft)) + " "), _vm.validDaysLeft < 3 ? _c('feather-icon', {
    staticClass: "icon-expiring-soon",
    attrs: {
      "icon": "AlertCircleIcon",
      "size": "18"
    }
  }) : _vm._e()], 1) : _vm._e()]) : _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v(" Ubezpieczenie nieważne ")]), _vm.insurance ? _c('h4', {
    staticClass: "insurance-info expired"
  }, [_vm._v(" " + _vm._s(_vm.$tc('base.cars.insurance.field.expiredSince', -_vm.validDaysLeft)) + " "), _c('feather-icon', {
    staticClass: "icon-expired",
    attrs: {
      "icon": "AlertCircleIcon",
      "size": "18"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "last-insurance"
  }, [_c('div', {
    staticClass: "last-insurance-header"
  }, [_vm._v(" okres ważności polisy ")]), _vm.insurance ? _c('div', {
    staticClass: "last-insurance-date"
  }, [_c('vs-button', {
    staticClass: "inspection-button",
    attrs: {
      "vs-variant": "light"
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "LinkIcon"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.formatDate.insurance(_vm.insurance.date_from)) + " - " + _vm._s(_vm.formatDate.insurance(_vm.insurance.date_to)) + " r. ")], 1) : _vm._e()])]), _c('div', {
    staticClass: "right-side"
  }, [_vm.insurance ? _c('details-table', {
    attrs: {
      "entries": _vm.entries,
      "i18n-group": "base.cars.insurance.field"
    },
    scopedSlots: _vm._u([{
      key: "header-phone",
      fn: function () {
        return [_vm._v(" nr tel. Assistance "), _c('br'), _c('br')];
      },
      proxy: true
    }, {
      key: "value-name",
      fn: function () {
        return [_c('crm-company-label', {
          attrs: {
            "integrated": _vm.insurance.insurance_company.company,
            "companyType": "provider"
          }
        })];
      },
      proxy: true
    }, {
      key: "value-file",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.insurance.file.file_link,
            "target": "_blank"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ExternalLinkIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.insurance.file.original_name) + " ")], 1)];
      },
      proxy: true
    }], null, false, 2501104041)
  }) : _vm._e()], 1)])] : [_c('div', {
    staticClass: "no-insurance"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" Samochód nie był jeszcze ubezpieczany ")], 1)], _c('base-insurance-create-modal', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "ok": function ($event) {
        return _vm.$emit('create:insurance');
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }