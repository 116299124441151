const defaultFields = {
  date_from: 'required',
  date_to: 'required|date_after:@date_from',
  policy_number: 'required',
  coverage: 'required',
  phone_number: 'required',
  crm_provider_id: 'required',
  base_resource_id: 'required',
  file: '',
};

const baseInsuranceFormValidations = {
  ...defaultFields,
};

export default baseInsuranceFormValidations;
