<template>
  <vs-modal
    :modalInstance="modalEditResource"
    @ok="updateResource"
  >
    <base-resource-form
      v-model="fields"
      :feedback="feedback"
      :type="type"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import baseResourceFormValidations from '@/services/forms/base/resource';
import { computed } from 'vue';
import showToast from '@/libs/toasts';
import { UpdateResourceSuccess, UpdateResourceError } from '@/libs/toasts/feedback/module/base';
import { BaseResourceExtraField, BaseResourceExtraFields, BaseResourceExtraFieldType } from '@/hooks/base/extraFields';
import useBaseProducerModelStore from '@/stores/module/base/producer/useBaseProducerModelStore';
import BaseResourceForm from '../forms/BaseResourceForm.vue';

export default {
  name: 'BaseResourceUpdateModal',
  emits: ['ok'],
  props: {
    type: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { modalEditResource } = useBaseModals();

    const producerModelStore = useBaseProducerModelStore();

    const validationRules = computed(() => {
      if (!props.type) return baseResourceFormValidations.default;
      return baseResourceFormValidations[props.type] ?? baseResourceFormValidations.default;
    });

    const {
      fields,
      feedback,
      areAllFieldsValid,
      nonEmptyFields,
      assignServerSideErrors,
      resetFields,
      assignFields,
    } = useValidatedForm({
      ...validationRules.value,
      id: 'required',
    });

    let resourceExtraData;

    const transformExtraData = (resourceExtraDataArray) => resourceExtraDataArray
      .reduce((acc, item) => {
        const fieldSlug = item.field.slug;

        if (Object.values(BaseResourceExtraField).includes(fieldSlug)) {
          acc[fieldSlug] = item.field_value?.id || item.value || null;
        }

        return acc;
      }, {});

    const generateExtraDataForUpdate = (extraData, fieldsData) => {
      const existingData = extraData.map((item) => {
        const fieldSlug = item.field.slug;
        const fieldType = BaseResourceExtraFields[fieldSlug];
        const value = fieldsData[fieldSlug];

        return {
          id: item.id,
          base_resource_field_slug: fieldSlug,
          ...(fieldType === BaseResourceExtraFieldType.Input ? { value } : {}),
          ...(fieldType === BaseResourceExtraFieldType.Select
            ? { base_resource_value_id: value }
            : {}),
        };
      });

      const newExtraData = Object.entries(BaseResourceExtraFields)
        .filter(([slug]) => slug in fieldsData
          && fieldsData[slug] != null
          && !existingData.some((item) => item.base_resource_field_slug === slug))
        .map(([slug, type]) => {
          const fieldValue = fieldsData[slug];

          return type === BaseResourceExtraFieldType.Input
            ? { base_resource_field_slug: slug, value: fieldValue }
            : {
              base_resource_field_slug: slug,
              base_resource_value_id: fieldValue,
            };
        });

      return [...existingData, ...newExtraData];
    };

    const prepareAndAssignFields = (resource) => {
      resourceExtraData = resource.extra_data;

      assignFields({
        ...resource,
        base_technology_id: resource.technologies?.map((t) => t.id),
        base_resource_daily_cost: resource.actual_daily_cost,
        base_resource_activity: {
          base_group_id: resource.last_activity?.base_group_id,
          base_resource_collaboration: resource.last_activity?.collaboration,
        },
        ...(props.type === 'car'
          ? {
            ...transformExtraData(resourceExtraData),
          }
          : {}),
      });
    };

    modalEditResource.value.on({
      open(data) {
        resetFields();

        if (data.fetch) {
          modalEditResource.value.isLoading = true;

          useBaseApi()
            .fetchResource(data.id)
            .then((res) => {
              prepareAndAssignFields(res.data.data);
            })
            .finally(() => {
              modalEditResource.value.isLoading = false;
            });
        } else {
          prepareAndAssignFields(data);
        }
      },
    });

    const updateResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalEditResource.value.isLoading = true;

      const model = producerModelStore.getById(fields.value.base_producer_model_id);

      const sendData = {
        ...nonEmptyFields.value,
        name: `${model.producer.name} ${model.name} ${fields.value.register_number}`,
        base_resource_activity: {
          ...fields.value.base_resource_activity,
          base_resource_collaboration: {
            ...fields.value.base_resource_activity?.base_resource_collaboration ?? {},
          },
        },
        ...(props.type === 'car'
          ? {
            extra_data: generateExtraDataForUpdate(
              resourceExtraData,
              fields.value,
            ),
          }
          : {}),
      };

      if (!sendData.base_resource_activity?.base_resource_collaboration?.crm_company_id) {
        delete sendData.base_resource_activity?.base_resource_collaboration;
      }

      useBaseApi()
        .updateResource(sendData.id, {
          ...sendData,
          base_resource_type_id: props.type,
        })
        .then(({ data }) => {
          modalEditResource.value.close();
          showToast(UpdateResourceSuccess, { name: data.data?.name });
          emit('ok', { ...data.data });
        })
        .catch(({ response }) => {
          assignServerSideErrors(response?.data?.errors);
          showToast(UpdateResourceError);
        })
        .finally(() => {
          modalEditResource.value.isLoading = false;
        });
    };

    return {
      modalEditResource,
      fields,
      feedback,
      updateResource,
    };
  },
  components: {
    VsModal,
    BaseResourceForm,
  },
};
</script>
