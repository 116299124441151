<template>
  <div class="investments-investment-picker vs-picker">
    <v-select
      v-model="selected"
      :filter="fuseSearch"
      :multiple="multiple"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="investments"
      :clearable="clearable"
      :reduce="reduce"
      :label="label"
      :disabled="disabled"
      :placeholder="placeholder"
    >
      <template #option="{ name }">
        {{ name }}
      </template>

      <template #selected-option="{ name }">
        {{ name }}
      </template>

      <template #no-options>
        {{ $t('component.picker.investment.noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import vSelect from 'vue-select';
import { computed, ref } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

export default {
  name: 'InvestmentsInvestmentPicker',
  emits: ['ready', 'input'],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customSet: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    label: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Wybierz inwestycję',
    },
    investmentId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const investments = ref([]);

    // FIXME: fetch method should be defined in store
    if (investments.value.length === 0) {
      useInvestmentsApi()
        .fetchPickerInvestments()
        .then(({ data }) => {
          investments.value = data.data;
          emit('ready');
        })
        .catch(() => {});
    }

    const selected = computed({
      get() {
        return props.value;
      },
      set(value) {
        if (!value) return;

        emit('input', value);
      },
    });

    const fuseSearch = (options, search) => {
      const fuse = new Fuse(options, {
        keys: ['resource.name'],
        threshold: 0.2,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    };

    return { selected, investments, fuseSearch };
  },
  components: {
    vSelect,
  },
};
</script>
